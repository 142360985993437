import React from 'react'

import { getLocalNodes } from '../../helpers/locale'
import Container from '../container'
import ArticlesList from './articlesList'
import FeaturedArticle from './featuredArticle'

const FeaturedArticles = (props): JSX.Element => {
  const { articles } = props
  const featuredArticles = getLocalNodes(articles.nodes)
  return (
    <Container>
      <FeaturedArticle article={featuredArticles[0]} />
      <ArticlesList
        articles={
          featuredArticles.length >= 8
            ? featuredArticles.slice(1, 8)
            : featuredArticles.slice(1, 5)
        }
      />
    </Container>
  )
}

export default FeaturedArticles
